import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { Account } from '@playbook/models';

export interface AccountState extends Account {
  token?: string;
}

@StoreConfig({ name: 'account' })
@Injectable({ providedIn: 'root' })
export class AccountStore extends Store<AccountState> {
  constructor() {
    super({});
  }
}
