import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Account } from '@playbook/models';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class AccountRemote {
  constructor(private http: HttpClient) {}

  getAccount() {
    return this.http.get<Account>(`${environment.apiUrl}me/`, { withCredentials: true });
  }

  checkSession() {
    return this.http.get(`${environment.apiUrl}accounts/session-status/`, {
      withCredentials: true,
    });
  }
}
