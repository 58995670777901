export const environment = {
  production: true,
  hmr: false,
  apiUrl: 'https://sandbox.api.playbook.vc/v1/',
  apiUrlV2: 'https://sandbox.api.playbook.vc/v2/',
  appUrl: 'https://sandbox.playbook.vc',
  firebase: {
    apiKey: 'AIzaSyCHi6M8ryhzVxBOmNtyvE7CjbZ_UkPWy-8',
    authDomain: 'playbook-admin-sandbox.firebaseapp.com',
    databaseURL: 'https://playbook-admin-sandbox.firebaseio.com',
    projectId: 'playbook-admin-sandbox',
    storageBucket: 'playbook-admin-sandbox.appspot.com',
    messagingSenderId: '254083993719',
    appId: '1:254083993719:web:7ab7e288da86803278b672',
    measurementId: 'G-YY56JYSJZY',
  },
  authentication: {
    url: 'http://sandbox.auth.playbook.vc',
    clientId: '1a222acd-d43b-4b94-9202-a2a380556771',
    redirectUri: 'https://sandbox.admin.playbook.vc',
  },
};
