import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { AccountState, AccountStore } from './account.store';

@Injectable({ providedIn: 'root' })
export class AccountQuery extends Query<AccountState> {
  currentAccount$ = this.select();
  token$ = this.select(state => state.token);

  constructor(protected store: AccountStore) {
    super(store);
  }

  get currentUser() {
    return this.getValue();
  }

  get token() {
    return this.getValue().token;
  }
}
