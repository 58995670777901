import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { AccountService } from '../modules/account/services/account.service';

@Injectable()
export class NotLoggedInGuard implements CanActivate {
  constructor(private accountService: AccountService, private router: Router) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.accountService.token().pipe(
      switchMap((token: string) => {
        if (!token) {
          this.router.navigate(['auth'], { queryParams: { state: state.url !== '/' ? btoa(state.url) : null } });
          return of(false);
        }
        return of(true);
      })
    );
  }
}
