import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { AccountQuery } from '../state/account.query';
import { AccountStore } from '../state/account.store';
import { AngularFireAuth } from '@angular/fire/auth';
import { AccountRemote } from './account.remote';
import { Account } from '@playbook/models';
import { Ability, AbilityBuilder } from '@casl/ability';
import { CookieService } from 'ngx-cookie-service';

@Injectable({ providedIn: 'root' })
export class AccountService {
  constructor(
    private accountRemote: AccountRemote,
    private accountStore: AccountStore,
    private accountQuery: AccountQuery,
    private afAuth: AngularFireAuth,
    private ability: Ability,
    private cookieService: CookieService
  ) {}

  getAccount() {
    return this.accountRemote.getAccount().pipe(
      map(account => ({ display_name: `${account.first_name} ${account.last_name}`, ...account } as Account)),
      tap(account => {
        this.accountStore.update({ ...account, token: this.cookieService.get('_pb') });
        this.updateAbilities(account);
      })
    );
  }

  currentUser() {
    return this.accountQuery.currentUser;
  }

  currentUser$() {
    return this.accountQuery.currentAccount$;
  }

  token() {
    return this.accountQuery.token$;
  }

  getToken() {
    return this.accountQuery.token;
  }

  async signOut() {
    localStorage.clear();
    await this.afAuth.signOut();
    location.reload();
  }

  private updateAbilities(account: Account) {
    const { can, rules } = new AbilityBuilder<Ability>();

    account.features
      .filter(feature => feature.startsWith('admin-'))
      .forEach(feature => {
        can('read', feature);
      });

    if (account.is_staff) {
      can('access', 'app-admin');
    }

    this.ability.update(rules);
  }

  get query() {
    return this.accountQuery;
  }

  checkSession() {
    return this.accountRemote.checkSession();
  }
}
