import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AccountService } from '../modules/account/services/account.service';

@Injectable()
export class LoggedInGuard implements CanActivate {
  constructor(private accountService: AccountService, private router: Router) {}
  canActivate() {
    return this.accountService.token().pipe(
      switchMap((token: string) => {
        if (token) {
          this.router.navigate(['/']);
          return of(false);
        }
        return of(true);
      })
    );
  }
}
